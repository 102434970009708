<template>
  <div>
    <v-row
      v-if="!loading"
      dense
    >
      <v-col
        v-if="edit"
        cols="12"
      >
        <span>Essayez de drag-n-drop les différents groupes ! Cliquer pour passer
          en optionel (càd "Avis" plutôt que "Décision").</span>
      </v-col>
      <v-col
        :md="edit ? 9 : 12"
        cols="12"
        class="px-0"
      >
        <v-timeline
          dense
          class="mx-1"
        >
          <v-timeline-item
            v-for="step of getSteps()"
            :key="step.i"
            fill-dot
            small
          >
            <template v-slot:icon>
              <v-avatar
                size="30"
                :color="stepColor(step.i)"
              >
                <span class="white--text">{{ step.i }}</span>
              </v-avatar>
            </template>
            <v-card
              outlined
              :color="cardColor(step.i)"
              :disabled="cardDisabled(step.i)"
              @dragover="(e) => dragover(e, step.i)"
              @dragleave="dragleave"
              @drop="(e) => drop(e, step.i)"
            >
              <v-card-actions>
                <v-chip-group>
                  <v-badge
                    v-for="(actor, i) in step.s"
                    :key="i"
                    content="?"
                    offset-x="28"
                    offset-y="16"
                    :value="!!actor.optional"
                    class="tinybadge"
                  >
                    <v-chip
                      :color="getGroupColor(actor)"
                      :class="
                        'mx-auto d-block mr-4 white--text ' +
                          (edit ? '' : 'disable-events')
                      "
                      :draggable="edit"
                      @dragstart="(e) => dragstart(e, actor)"
                      @click="toogleOptional(actor)"
                    >
                      {{ getActorName(actor) }}
                    </v-chip>
                  </v-badge>
                </v-chip-group>
              </v-card-actions>
            </v-card>
          </v-timeline-item>
          <!-- TOOLS -->
          <template v-if="edit">
            <v-timeline-item
              v-for="(item, i) in tools()"
              :key="'tool' + i"
              fill-dot
              small
            >
              <template v-slot:icon>
                <v-avatar
                  size="30"
                  color="blue lighten-2"
                >
                  <v-icon color="white">
                    {{ item.icon }}
                  </v-icon>
                </v-avatar>
              </template>
              <v-card
                outlined
                :color="cardColor(item.key)"
                height="64px"
                class="text-center"
                @dragover="(e) => dragover(e, item.key)"
                @dragleave="dragleave"
                @drop="(e) => drop(e, item.key)"
              >
                <v-col cols="auto">
                  {{ item.text }}
                </v-col>
              </v-card>
            </v-timeline-item>
          </template>
        </v-timeline>
        <TimelineHelp />
      </v-col>
      <v-col
        v-if="edit"
        cols="12"
        md="3"
        class="px-0"
      >
        <v-card
          outlined
          class="fill-height px-4 mx-2"
        >
          <v-card-title>Disponibles</v-card-title>
          <v-card-text>
            <v-chip-group column>
              <v-chip
                v-for="(group, i) in available_groups"
                :key="i"
                :color="getGroupColor(group)"
                :class="'d-block white--text ' + (edit ? '' : 'disable-events')"
                :draggable="edit"
                @dragstart="(e) => dragstartnew(e, group._id)"
              >
                {{ group.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-progress-circular
      v-else
      class="d-block mx-auto"
      indeterminate
    />
  </div>
</template>

<script>
import TimelineHelp from "@/components/Utils/TimelineHelp";
import utils from "@/utils";

export default {
  components: { TimelineHelp },
  props: {
    event: Object,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: this.edit,
      hovering_on: undefined,
      available_groups: [],
    };
  },
  computed: {
    currentLevel() {
      return this.event.fop.foplevel;
    },
  },
  mounted() {
    if (this.edit) {
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/groups/getAllStepsGroups")
        .then((res) => {
          this.available_groups = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    }
  },
  methods: {
    getSteps() {
      return Object.values(utils.mapSteps(this.event.fop.steps)).map((x) => {
        return {
          s: x,
          i: x[0].level,
        };
      });
    },
    getGroupColor(g) {
      if (this.edit) return "grey darken-1";
      return {
        waiting: "grey lighten-1",
        pending: "blue",
        consent: "green",
        no_consent: "error",
        bypassed: "blue-grey darken-1",
        suspended: "orange",
      }[g.step_status];
    },
    getActorName(a) {
      if (a.group && a.group.name) return a.group.name;
      let group = this.available_groups.filter((x) => x._id == a.group._id)[0];
      if (group) return group.name;
      return "Unknown";
    },
    stepColor(i) {
      if (this.currentLevel == undefined || i > this.currentLevel) {
        return "grey";
      } else if (i == this.currentLevel) {
        if (this.event.fop.status == "suspended") {
          return "orange";
        } else {
          return "primary";
        }
      }
      return "green";
    },
    cardColor(i) {
      if (!this.edit) return;
      if (this.hovering_on == i) {
        if (i == "delete") return "red";
        return "green";
      }
    },
    cardDisabled(i) {
      return this.edit && i <= this.event.fop.foplevel;
    },
    setLevel(id, level) {
      var steps;
      if (level == "delete") {
        steps = this.event.fop.steps.filter((x) => x._id != id);
      } else {
        if (level == "new") {
          level = Math.max(...this.event.fop.steps.map((x) => x.level)) + 1;
        }
        // First set the new level
        var curgroup;
        steps = this.event.fop.steps.map((x) => {
          if (x._id == id) {
            x.level = level;
            curgroup = x.group._id;
          }
          return x;
        });
        // Then deduplicate the same group + level
        steps = steps.filter(
          (x) => x.level != level || x.group._id != curgroup || x._id == id
        );
      }
      // Now check that there is no gap, if so move everything
      let mapped = utils.mapSteps(steps);
      let maxlevel = Math.max(...Object.keys(mapped).map((x) => parseInt(x)));
      for (let i = maxlevel; i > 0; i--) {
        if (!(i - 1 in mapped)) {
          steps = steps.map((x) => {
            if (x.level >= i) x.level = x.level - 1;
            return x;
          });
        }
      }
      this.event.fop.steps = steps;
    },
    addNewGroup(id, group, level) {
      this.event.fop.steps.push({
        group: {
          _id: group,
        },
        level: -1,
        optional: false,
        _id: id,
      });
      this.setLevel(id, level);
    },
    // Handle drag and drop
    dragstart(event, actor) {
      event.dataTransfer.setData("id", actor._id);
      event.dataTransfer.setData("create", false);
    },
    dragstartnew(event, group) {
      event.dataTransfer.setData("id", crypto.randomUUID().replaceAll("-", ""));
      event.dataTransfer.setData("group", group);
      event.dataTransfer.setData("create", true);
    },
    dragover(event, i) {
      event.preventDefault();
      this.hovering_on = i;
    },
    dragleave() {
      this.hovering_on = undefined;
    },
    drop(event, i) {
      event.preventDefault();
      this.hovering_on = undefined;
      if (event.dataTransfer.getData("create") == "true") {
        this.addNewGroup(
          event.dataTransfer.getData("id"),
          event.dataTransfer.getData("group"),
          i
        );
      } else {
        this.setLevel(event.dataTransfer.getData("id"), i);
      }
    },
    toogleOptional(actor) {
      let i = this.event.fop.steps.findIndex((x) => x._id == actor._id);
      if (i != -1) {
        this.event.fop.steps[i].optional = !this.event.fop.steps[i].optional;
      }
      this.$forceUpdate();
    },
    deleteDisabled() {
      return this.event.fop.steps.length == 1;
    },
    tools() {
      let res = [
        {
          icon: "mdi-plus",
          key: "new",
          text: "Déposer un groupe ici pour créer un niveau",
        },
      ];
      if (!this.deleteDisabled())
        res.push({
          icon: "mdi-delete",
          key: "delete",
          text: "Déposer un groupe ici pour le supprimer",
        });
      return res;
    },
  },
};
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
</style>

<style>
.tinybadge .v-badge__badge {
  font-size: 10px;
  min-width: 14px;
  width: 14px;
  height: 14px;
  padding: 0.3em 4px 3px 4px;
}
</style>