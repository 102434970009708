<template>
  <div class="text-right body-2">
    <v-badge
      color="green"
      inline
      dot
      class="mr-2"
    >
      {{ calendar ? "Validé" : "Favorable" }}
    </v-badge>
    <v-badge
      v-if="!calendar"
      color="error"
      inline
      dot
      class="mr-2"
    >
      Défavorable
    </v-badge>
    <v-badge
      color="orange"
      inline
      dot
      class="mr-2"
    >
      {{ calendar ? "Suspendu" : "Suspendue" }}
    </v-badge>
    <v-badge
      :color="calendar ? 'grey': 'blue'"
      inline
      dot
      class="mr-2"
    >
      En attente
    </v-badge>
    <v-badge
      v-if="calendar"
      color="blue"
      inline
      dot
      class="mr-2"
    >
      Sans fop
    </v-badge>
    <span
      v-if="!calendar"
      class="mr-2"
    >Avis uniquement<span class="ml-1 text-subtitle-2">?</span></span>
  </div>
</template>

<script>

export default {
  props: {
    calendar: Boolean,
  }
}
</script>
