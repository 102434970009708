export default {
	ellispsis(text) {
		if (!text) return ""
		if (text.length <= 140) return text
		return text.substr(0, text.lastIndexOf(" ", 140)) + "..."
	},
	toDate(date) {
		let eng_date = new Date(date)
		let add0_day = eng_date.getDate() < 10 ? '0' : ''
		let add0_month = eng_date.getMonth() + 1 < 10 ? '0' : ''
		return add0_day + eng_date.getDate() + '/' + add0_month + (eng_date.getMonth() + 1) + '/' + eng_date.getFullYear()
	},
	toHours(time) {
		time = new Date(time)
		return time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
	},
	datediff(d1, d2) {
		return Math.round((d1 - d2) / (1000 * 60 * 60 * 24))
	},
	mapListToDict(lst, key, fct) {
		return Object.assign(
			{},
			...lst.map(x => ({
				[x[key]]: fct(x),
			}))
		)
	},
	getFopFieldIcon(field_type) {
		if (field_type == "text") return "mdi-format-quote-open"
		if (field_type == "textarea") return "mdi-format-text"
		if (field_type == "number") return "mdi-numeric"
		if (field_type == "date") return "mdi-calendar"
		if (field_type == "email") return "mdi-at"
		return "mdi-help"
	},
	mapSteps(to_map_steps) {
		let steps = {}
		// Map steps by level
		for (let x of to_map_steps) {
			if (!steps[x.level]) steps[x.level] = []
			steps[x.level].push(x);
		}
		return steps
	},
}
